
.card {
    background: white;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 30px;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 1px 1px rgba(0,0,0,0.01), 
                0 2px 2px rgba(0,0,0,0.01), 
                0 4px 4px rgba(0,0,0,0.01), 
                0 8px 8px rgba(0,0,0,0.01),
                0 16px 16px rgba(0,0,0,0.01), 
                0 32px 32px rgba(0,0,0,0.01);
    &:hover {
        cursor: pointer;
        transform: translate(0px, -4px);
        box-shadow: 0 1px 2px rgba(0,0,0,0.04), 
        0 2px 4px rgba(0,0,0,0.04), 
        0 4px 8px rgba(0,0,0,0.04), 
        0 8px 16px rgba(0,0,0,0.04),
        0 16px 32px rgba(0,0,0,0.04), 
        0 32px 64px rgba(0,0,0,0.04);
    }

    &.new::before {
        content: "";
        width: 4px;
        border-radius: 2px;
        background-color: $mid-red;
        display: block;
        position: absolute;
        left: 5px;
        top: 5px;
        bottom: 5px;
    }

    &.disabled {
        opacity: 0.6;
    }

    .selected {
        border: 3px solid $dark-grey;
    }

}

.box {
    background: white;
    width: 100%;
    padding: 30px;
    border: 1px solid $mid-grey-20;
    border-radius: 5px;
    margin-bottom: 20px;
}


.floatingTile {
    background: white;
    width: 100%;
    margin-bottom: 20px;
    padding: 50px 20px 50px 20px;
    border-radius: 35px 15px 70px 15px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 1px 1px rgba(0,0,0,0.01), 
                0 2px 2px rgba(0,0,0,0.01), 
                0 4px 4px rgba(0,0,0,0.01), 
                0 8px 8px rgba(0,0,0,0.01),
                0 16px 16px rgba(0,0,0,0.01), 
                0 32px 32px rgba(0,0,0,0.01);
}