
@import 'partials/grid.scss';
@import 'partials/colors.scss';
@import 'partials/fonts.scss';
@import 'partials/cards.scss';
@import 'partials/table.scss';
@import 'partials/mapbox-gl.scss';


html, body { 
    height: 100%;     
    background: $dark-grey;
}

body { margin: 0; font-family: Roboto, sans-serif; }

.right {
    float: right;
}

.full-width {
    width: 100%;
}

.logo {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    background-image: url('./../assets/myeventpass.svg');
    background-position: center;
    background-size: contain;
    display: block;
    text-indent: -9999px;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: $mid-red;
}

.mat-icon.mat-primary {
    color: $mid-red;
}

.green {
    color: mediumseagreen;
}

.red {
    color: $mid-red;
}

.mt-4 {
    margin-top: 20px;
}

.mb-4 {
    margin-bottom: 20px;
}