//  COLORS

$dark-grey :        rgb(30,35,55);
$dark-grey-10 :     rgba(30,35,55,0.1);
$dark-grey-20 :     rgba(30,35,55,0.2);
$dark-grey-90 :     rgba(30,35,55,0.9);
$mid-grey :         rgb(125,140,165);
$mid-grey-60 :      rgba(125,140,165,0.6);
$mid-grey-20 :      rgba(125,140,165,0.2);
$mid-grey-5 :       rgba(125,140,165,0.05);
$mid-grey-5-full :  rgba(35,40,60,1);

$light-grey :       rgb(244,244,245);

$dark-red :         rgb(225,35,70);
$mid-red :          rgb(245,40,80);
$mid-red-20 :       rgba(245,40,80,0.2);

$mid-yellow :       rgb(255,190,0);



//  BACKGROUNDS

@mixin bg-light-grey() {
    background-color: $light-grey;
}

@mixin gradient() {
    background: -moz-linear-gradient(15deg,  $dark-red 18%,  $mid-red 100%);
    background: -webkit-linear-gradient(15deg,  $dark-red 18%, $mid-red 100%);
    background: linear-gradient(15deg,  $dark-red 18%, $mid-red 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$dark-red}, endColorstr=#{$mid-red},GradientType=1 );
}

@mixin layer() {
    background: -moz-linear-gradient(7deg,  rgba(30,35,55,0.85) 35%,  rgba(30,35,55,0.01) 60%);
    background: -webkit-linear-gradient(7deg,  rgba(30,35,55,0.85) 35%, rgba(30,35,55,0.01) 60%);
    background: linear-gradient(7deg,  rgba(30,35,55,0.85) 35%, rgba(30,35,55,0.01) 60%);
}

.bg-light-grey {
    @include bg-light-grey();
}

.white {
    color: white;
}

.yellow {
    color: $mid-yellow;
}

.light-grey {
    color: $mid-grey-20;
}