.contentTable {


    width: 100%;
    tr {
    }

    tr:nth-child(odd):not(:first-child) {
        background: $light-grey
    }
        
    th {
        padding: 15px;

        text-align: left;
    }

    td {
        text-align: left;
        padding: 15px;
    }
}