h1 {
    position: relative;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 0;
    @media screen  and (min-width: 481px) and (max-width: 991px) {
        font-size: 60px;        
        line-height: 60px;
    }

    @media screen and (max-width: 480px) {
        font-size: 40px;        
        line-height: 45px;
    }
    
    .smallBadge {
        display: inline-block;
        border: 1px solid $mid-grey;
        min-width: 20px;
        text-align: center;
        height: 30px;
        font-size: 14px;
        line-height: 10px;
        border-radius: 15px;
        padding: 10px;
        color: $mid-grey;
        position: absolute;
        top: 18px;
        margin-left: 10px;
        text-transform: uppercase;
    }
    
}

h2 {
    font-weight: 600;
    margin-bottom: 20px;

    .red {
        color: $mid-red;
    }
}

h3 {
    font-size: 24px;
    color: $mid-grey;
    font-weight: 600;
}

h4 {
    margin-bottom: 50px;

}

p {
    font-size: 18px;
    letter-spacing: 0.3px;
    margin: 0 0 15px 0;
}

.legal {
    color: $mid-grey-60;
    font-size: 14px;

}

.center {
    text-align: center;
}

.right {
    text-align: right;
}


.right-md {         
     @media screen and (min-width: 481px) {
        text-align: right;
    }  
}

.center-md {
    @media screen and (min-width: 481px) {
        text-align: center;
    }  
 }

.center-xs {
    @media screen and (max-width: 480px) {
        text-align: center;
    }  
}

.right-xs {         
    @media screen and (max-width: 480px) {
       text-align: right;
   }  
}


